import { Airports } from './Airports';

export class SearchParams {
    constructor(
      public way?: string,
      public aiportDep?: Airports,
      public aiportArr?: Airports,
      public startDt?: Date,
      public endDt?: Date,
      public rangeDates?: Date[],
      public multiDay?: number,
      public adult?: number,
      public children?: number,
      public personText?: string,  
    ) { }
  }