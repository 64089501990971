import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Article } from '../models/Article';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ArticlesService {
  constructor(private http: HttpClient) {}

  getArticles(pageId = 0, limit = 10): Observable<Array<Article>> {
    return this.http.get<Array<Article>>(
      environment.apiUrl +
        '/api/getarticles?pageId=' +
        pageId +
        '&limit=' +
        limit
    );
  }

  getArticle(slug: String): Observable<Article> {
    return this.http.get<Article>(
      environment.apiUrl + '/api/getarticle?slug=' + slug
    );
  }
}
