import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Airports } from '../models/Airports';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AutocomplateService {

  constructor( private http: HttpClient) { }

  getAitports( query ): Observable<Array<Airports>> {
    return this.http.get<Array<Airports>>(environment.apiUrl + '/api/autocomplate?q=' + query);
  }
}
